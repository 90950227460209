<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="announcements"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          :is-loading="isLoadingData"
          title="Pengumuman"
          subtitle="Halaman untuk menambahkan pengumuman"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Pengumuman': 'Edit Pengumuman'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="announcement.title"
                label="Judul"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <h4
              for=""
              class="mb-4"
            >
              Set waktu
            </h4>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-radio-group
                v-model="announcement.time_set"
                row
                class="mt-0 mb-2"
              >
                <v-radio
                  v-for="value in valueRadio"
                  :key="value.id"
                  :label="value.status"
                  :value="value.status"
                  @click="setPermanent"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <h4
              class="mb-4"
            >
              Tanggal Terbit
            </h4>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="startDate"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="announcement.start_date"
                    label="Tanggal Terbit"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    :disabled="isDateField"
                    clearable
                    v-on="on"
                    @click="autofillStartDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="announcement.start_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="startDate = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="endDate"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="announcement.end_date"
                    label="Tanggal Berakhir"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    :disabled="isDateField"
                    clearable
                    v-on="on"
                    @click="autofillEndDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="announcement.end_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="endDate = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="announcement.content"
                label="Pesan"
                outlined
                dense
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Pengumuman"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'Announcements',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      valueRadio: [
        {
          index: 1,
          status: 'Permanen',
        },
        {
          index: 2,
          status: 'Jangka Waktu',
        },
      ],
      settingMessage: [
        {
          index: 1,
          title: 'Notifikasi Pesan',
          checkbox: true,
        },
        {
          index: 2,
          title: 'Dashboard Notifikasi',
          checkbox: false,
        },
      ],
      school: [],
      kadis_uuid: null,
      startDate: false,
      endDate: false,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      isDateField: false,
      announcement: {
        title: '',
        start_date: '',
        end_date: '',
        content: '',
        time_set: '',
      },
      service: 'announcementhod',
      page: 1,
      totalItems: 10,
      totalPages: 0,
      dialog: null,
      search: '',
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'JUDUL', value: 'title' },
        { text: 'PESAN', value: 'content' },
        { text: 'SET WAKTU', value: 'status' },
        { text: 'TANGGAL  UPLOAD', value: 'created' },
        { text: 'TAMPIL PADA', value: 'start_date' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      announcements: [],
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  watch: {
    announcement: {
      handler() {
        const valid = []
        let requiredField = []
        if (this.announcement.time_set === 'Jangka Waktu') {
          requiredField = ['start_date', 'end_date', 'title', 'content', 'time_set']
        } else {
          requiredField = ['title', 'content']
        }
        Object.entries(this.announcement).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listAnnouncement(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listAnnouncement()
  },
  methods: {
    resetForm() {
      this.announcement.title = ''
      this.announcement.start_date = ''
      this.announcement.end_date = ''
      this.announcement.time_set = ''
      this.announcement.content = ''
      this.isDateField = false
    },
    autofillStartDate() {
      this.announcement.start_date = new Date().toISOString().substr(0, 10)
    },
    autofillEndDate() {
      this.announcement.end_date = new Date().toISOString().substr(0, 10)
    },
    setPermanent() {
      if (this.announcement.time_set === 'Permanen') {
        this.isDateField = true
        this.announcement.start_date = ''
        this.announcement.end_date = ''
      }
      if (this.announcement.time_set === 'Jangka Waktu') {
        this.isDateField = false
      }
    },
    async listAnnouncement(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        kadis_uuid: this.kadis_uuid,
      }).then(({ data }) => {
        this.announcements = data.data.map((announcement, index) => ({
          ...announcement,
          index: index + 1,
          created: this.getFormatDate(announcement.created_at),
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('title', this.announcement.title)
      this.formData.append('content', this.announcement.content)
      if (this.announcement.time_set !== 'Permanen') {
        this.formData.append('start_date', this.announcement.start_date)
        this.formData.append('end_date', this.announcement.end_date)
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.announcement.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listAnnouncement(this.filterQuery)
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('kadis_uuid', this.kadis_uuid)
      this.formData.append('title', this.announcement.title)
      this.formData.append('content', this.announcement.content)
      if (this.announcement.time_set !== 'Permanen') {
        this.formData.append('start_date', this.announcement.start_date)
        this.formData.append('end_date', this.announcement.end_date)
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listAnnouncement(this.filterQuery)
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.announcement.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.announcement.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listAnnouncement(this.filterQuery)
    },
    showFormAdd() {
      this.resetForm()
      this.dialog = 'add'
      this.modalDialog = true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.announcement = {}
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.announcement = data.data
        if (data.data.end_date === null) {
          this.announcement.time_set = 'Permanen'
        } else {
          this.announcement.time_set = 'Jangka Waktu'
        }
      })
      this.modalDialog = true
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${year}-${month.slice(-2)}-${day.slice(-2)} ${time}`
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listAnnouncement(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        // if (el.school === undefined) {
        //   this.kadis_uuid = el.uuid

        //   return
        // }
        // if (el.school === null) {
        //   this.showSnackbar({
        //     text: 'you dont have access to school forum',
        //     color: 'error',
        //   })

        //   return
        // }
        this.kadis_uuid = el.uuid
      })

      return true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
